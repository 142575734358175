import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import { ContractorActions } from '../../../../actions';
import { notify } from '../../../../components/ui/Toast/Toast';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import Popup from '../../../../components/ui/Popup';
import ContractorInvoicePreviewPopup from '../../popups/dealDetailPopups/SpendingContractorPreviewPopup';
import SpendingContractorPopup from '../../popups/dealDetailPopups/SpendingContractorPopup';
import { dealSpendingContractorsTableConfig } from '../../configs/sectionConfigs/dealSpendingContractorTableConfig';

const SubContractingSection = ({
    dealData,
    buttonsDisabled,
    setButtonsDisabled,
    blueStyle,
    data,
    refreshData,
    contractorInvoicesTableLoading,
    setContractorInvoicesFilterQuery,
    contractorInvoicesPaginationMetadata,
    handleGetAllContractorInvoicesData,
    cannotBeModified
}) => {
    // const [spendingsContractorData, setSpendingsContractorData] = useState(null);
    const [spendingContractorToBeUpdated, setSpendingContractorToBeUpdated] = useState(null);
    const [spendingContractorPopupView, setSpendingContractorPopupView] = useState(false);
    const [spendingContractorPreviewPopupView, setSpendingContractorPreviewPopupView] = useState(false);
    const [deleteContractorLoading, setDeleteContractorLoading] = useState(null);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const getAllSpendingContractorInvoices = async (query = '') => {
        setContractorInvoicesFilterQuery(query);
    };

    // // Get Spendinds Contractor Data
    // useEffect(() => {
    //     (async () => {
    //         await getAllSpendingContractorInvoices();
    //     })();
    // }, []);

    // Delete Table Item
    const handleDeleteSpendingsContractor = async (contractorId) => {
        setButtonsDisabled(true);
        setDeleteContractorLoading(contractorId);
        const result = await ContractorActions.deleteContractorInvoice(userData, contractorId);

        if (result.success) {
            await refreshData(true);
            notify('La factures sous/co traitants a bien été supprimé');
        }
        else {
            notify('Erreur lors de la suppression la factures sous/co traitants');
        }

        setDeleteContractorLoading(null);
        setButtonsDisabled(false);
    };

    // Edit Table Item
    const handleEditSpendingContractor = (item) => {
        setSpendingContractorToBeUpdated(item);
        setSpendingContractorPopupView(true);
    }

    // Preview spending contractor Table Item file
    const handleSpendingContractorPreview = (item) => {
        setSpendingContractorToBeUpdated(item);
        setSpendingContractorPreviewPopupView(true);
    };

    const spendingContractorsTableConfig = dealSpendingContractorsTableConfig(
        buttonsDisabled,
        deleteContractorLoading,
        handleEditSpendingContractor,
        handleDeleteSpendingsContractor,
        handleSpendingContractorPreview,
        blueStyle,
        getAllSpendingContractorInvoices,
        userData,
        cannotBeModified
    );

    // Reset item selected when popup is closed
    useEffect(() => {
        if (!spendingContractorPopupView && !spendingContractorPreviewPopupView) {
            setSpendingContractorToBeUpdated(null);
        }
    }, [spendingContractorPopupView, spendingContractorPreviewPopupView])

    return (
        <div>
            <div className='mt-12'>
                <ContainerCard
                    title={dealData.type === 'framework' || dealData.type === 'purchase_order' ? 'Bilan des factures sous/co-traitants' : 'Factures sous/co-traitant'}
                    buttonContent='Nouvelle facture sous/co-traitant'
                    onButtonClick={() => setSpendingContractorPopupView(true)}
                    blueStyle={blueStyle}
                    coloredButtonDisplayed={!cannotBeModified && !(dealData.type === 'framework' || dealData.type === 'purchase_order')}
                >
                    <Table
                        data={data || []}
                        tableConfig={spendingContractorsTableConfig}
                        loading={contractorInvoicesTableLoading}
                        paginationMetadata={contractorInvoicesPaginationMetadata}
                        onPageNumberChange={handleGetAllContractorInvoicesData}
                    />
                </ContainerCard>
            </div>

            <Popup
                view={spendingContractorPopupView}
                setView={setSpendingContractorPopupView}
                width={'1098px'}
                xOverflow=''
            >
                <SpendingContractorPopup
                    userData={userData}
                    dealData={dealData}
                    invoiceContractorToBeUpdated={spendingContractorToBeUpdated}
                    setView={setSpendingContractorPopupView}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                />
            </Popup>

            <ContractorInvoicePreviewPopup
                view={spendingContractorPreviewPopupView}
                setView={setSpendingContractorPreviewPopupView}
                item={spendingContractorToBeUpdated}
                type='invoice'
            />
        </div>
    )
}

export default SubContractingSection;
