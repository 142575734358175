import { createBrowserHistory } from '@remix-run/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    AttachmentActions,
    ContractorActions,
    CustomerActions,
    DealActions,
    DealOriginsActions,
    ExpenseActions,
    InvoiceActions,
    MissionActions,
    ProductionActions,
    SubsidiaryActions,
    UserActions
} from '../../actions';
import Layout from '../../components/layout';
import ConfirmationPopup from '../../components/ui/ConfirmationPopup';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import Popup from '../../components/ui/Popup';
import RoadStep from '../../components/ui/RoadStep';
import { notify, Toast } from '../../components/ui/Toast/Toast';
import TopBanner from '../../components/ui/TopBanner';
import { firstDayYear, lastDayYear, standardFormat } from '../../utils';
import { fetchSelectData } from './../../utils';
import AbandonedPopup from './components/dealDetailComponents/AbandonedPopup';
import AnalysisDeadlinePopup from './components/dealDetailComponents/AnalysisDeadlinePopup';
import BusinessRoadstepPopup from './components/dealDetailComponents/BusinessRoadstepPopup';
import DealDetailsNavigation from './components/dealDetailComponents/DealDetailsNavigation';
import GoNoGoPopup from './components/dealDetailComponents/GoNoGoPopup';
import RefusalMotiff from "./components/dealDetailComponents/RefusalMotiff";
import ResponseDeadlinePopup from './components/dealDetailComponents/ResponseDeadlinePopup';
import StartEndDatePopup from './components/dealDetailComponents/StartEndDatePopup';
import { bannerDealsConfig } from './configs/dealDetailConfigs/bannerDealsConfig';
import roadStepDealsConfig from './configs/dealDetailConfigs/roadStepDealsConfig';
import InvoicePopup from './popups/dealDetailPopups/InvoicePopup';

function DealDetailsPage() {
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // ------------
    // Data store states
    const [missionTableLoading, setMissionTableLoading] = useState(false);
    const [spendingsTableLoading, setSpendingsTableLoading] = useState(false);
    const [contractorInvoicesTableLoading, setContractorInvoicesTableLoading] = useState(false);
    const [invoicesTableLoading, setInvoicesTableLoading] = useState(false);

    const [dealData, setDealData] = useState({});
    const [parentData, setParentData] = useState(null);
    const [missionsData, setMissionsData] = useState([]);
    const [spendingsData, setSpendingsData] = useState(false);
    const [spendingsContractorData, setSpendingsContractorData] = useState([]);

    const [missionPaginationMetadata, setMissionPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 15
    });
    const [missionFilterQuery, setMissionFilterQuery] = useState('');

    const [spendingsPaginationMetadata, setSpendingsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    })
    const [spendingsFilterQuery, setSpendingsFilterQuery] = useState('');

    const [contractorInvoicesPaginationMetadata, setContractorInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    })
    const [contractorInvoicesFilterQuery, setContractorInvoicesFilterQuery] = useState('');

    const [invoicesPaginationMetadata, setInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    })
    const [invoicesFilterQuery, setInvoicesFilterQuery] = useState('');

    const [productionsPaginationMetadata, setProductionsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 15
    });
    const [productionsFilterQuery, setProductionsFilterQuery] = useState('');

    const [expensesPaginationMetadata, setExpensesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 15
    })
    const [expensesFilterQuery, setExpensesFilterQuery] = useState('');

    const [invoiceData, setInvoiceData] = useState(false);
    const [productionsData, setProductionsData] = useState(false);
    const [expensesData, setExpensesData] = useState(false);
    const [attachmentsData, setAttachmentsData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [deleteDealLoading, setDeleteDealLoading] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [lossMotifVisible, setLossMotifVisible] = useState(false);
    const [analysisDeadlineVisible, setAnalysisDeadlineVisible] = useState(false);
    const [responseDeadlineVisible, setResponseDeadlineVisible] = useState(false);
    const [businessVisible, setBusinessVisible] = useState(false);
    const [refuseAcceptDeal, setRefuseAcceptDeal] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [goNoGoPopupVisible, setGoNoGoPopupVisible] = useState(false);
    const [abandonedPopupVisible, setAbandonedPopupVisible] = useState(false);
    const [startEndDateVisible, setStartEndDateVisible] = useState(false);
    const [missionsInProgressPopup, setMissionsInProgressPopup] = useState(false);
    const [missionsInProgressIds, setMissionsInProgressIds] = useState([]);
    const [unissuedInvoicesPopup, setUnissuedInvoicesPopup] = useState(false);
    const [invoicePopupView, setInvoicePopupView] = useState(false);
    const [budgetAlertVisible, setBudgetAlertVisible] = useState(false);

    const [customers, setCustomers] = useState(null);
    const [ACBC, setACBC] = useState(null);
    const [contractors, setContractors] = useState(null);
    const [dealDas, setDealDas] = useState(null);
    const [dealOrigins, setDealOrigins] = useState(null);
    const [subsidiaryCategories, setSubsidiaryCategories] = useState(null);
    const [ressources, setRessources] = useState(null);
    const [customerType, setCustomerType] = useState(null);

    const [datePicker, setDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    })
    const [datePickerLoading, setDatePickerLoading] = useState(false);

    const [partnerValues, setPartnerValues] = useState([
        { id: Math.random(), ressource: null, role: '' }
    ]);

    const [productionRessourcesValues, setProductionRessourcesValues] =
    useState([
        {
            id: null,
            ressource: null,
            intervention_days: 0,
            daily_rate: 0,
            budget: 0
        }
    ]);

    const [saleValues, setSaleValues] = useState([
        {
            id: Math.random(),
            percent: '',
            ressource: {
                id: userData.userId,
                username: userData.userProfileName,
                first_name: userData.name.split(' ').shift(),
                last_name: userData.name.split(' ').slice(1).join(' ')
            }
        }
    ]);

    const [pilotsValues, setPilotsValues] = useState([
        {
            id: Math.random(),
            daily_rate: '',
            budget:'',
            ressource: {
                id: userData.userId,
                username: userData.userProfileName,
                first_name: userData.name.split(' ').shift(),
                last_name: userData.name.split(' ').slice(1).join(' ')
            }
        }
    ]);

    const [isTraining, setIsTraining] = useState(false);
    const isInternal = dealData.customer?.name === "Terre d'avance" || dealData.customer?.name === "Terre d'avance formation";

    // const dasS = useSelector((state) => state.DasReducer.das);

    const location = useLocation();
    const history = createBrowserHistory();

    const {id} = useParams();
    const navigate = useNavigate();

    const cannotBeModified = dealData.status === 900;

    const handleGetAllMissionsData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setMissionTableLoading(true);
            setMissionsData([]);
        }

        const result = await MissionActions.getAllMissions(userData,
            `&deal=${id}&page_size=${missionPaginationMetadata.pageSize}&display=${userData.userProfileName}&${missionFilterQuery}`, page);

        if (result.success) {
            setMissionsData(result.data);
            setMissionPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (missionPaginationMetadata.totalItems !== result.metadata.count) {
                setMissionPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des missions');
        }

        setMissionTableLoading(false);
    };

    const handleGetAllSpendingsData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setSpendingsTableLoading(true);
            setSpendingsData([]);
        }

        const result = await ExpenseActions.getAllExpenses(
            userData,
            '',
            '',
            '',
            `&page_size=${missionPaginationMetadata.pageSize}&${dealData.type === "framework" || dealData.type === "purchase_order" ? `deal_parent_id=${dealData.id}` : `deal=${id}`}&report=null&page=${page}&${spendingsFilterQuery}`
        );

        if (result.success) {
            setSpendingsData(result.data.filter((expense) => !expense.report));

            setSpendingsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (spendingsPaginationMetadata.totalItems !== result.metadata.count) {
                setSpendingsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des frais');
        }

        setSpendingsTableLoading(false);
    };

    const handleGetAllContractorInvoicesData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setContractorInvoicesTableLoading(true);
            setSpendingsContractorData([]);
        }
        console.log("dealData.type!!!!!!", dealData.type);
        
        const result = await ContractorActions.getAllContractorInvoices(
            userData, `page_size=${contractorInvoicesPaginationMetadata.pageSize}&page=${page}&${contractorInvoicesFilterQuery}&${dealData.type === "framework" || dealData.type === "purchase_order" ? `deal_parent_id=${dealData.id}` : `deal=${id}`}`);

        if (result.success) {
            setSpendingsContractorData(result.data.results);

            setContractorInvoicesPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (contractorInvoicesPaginationMetadata.totalItems !== result.metadata.count) {
                setContractorInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur lors de la récupération des factures sous traitants');
        }

        setContractorInvoicesTableLoading(false);
    };

    const handleGetAllInvoices = async (page, activeLoading = true ) => {
        if(activeLoading) {
            setInvoicesTableLoading(true);
            setInvoiceData([]);
        }

        const result = await InvoiceActions.getAllInvoices(userData,
            `&page_size=${invoicesPaginationMetadata.pageSize}&${invoicesFilterQuery}&${dealData.type === "framework" || dealData.type === "purchase_order" ? `deal_parent_id=${dealData.id}` : `deal=${id}`}`, page);

        if (result.success) {
            setInvoiceData(result.data);
            setInvoicesPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (invoicesPaginationMetadata.totalItems !== result.metadata.count) {
                setInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des factures');
        }

        setInvoicesTableLoading(false);
    };

    const handleGetAllProductions = async (page, activeLoading = true) => {
        if(activeLoading) {
            setDatePickerLoading(true);
            setProductionsData([]);
        }

        const datePickerBefore = standardFormat(new Date(datePicker.endDate));
        const datePickerAfter = standardFormat(new Date(datePicker.startDate));

        const optionalProps = {
            deal: id
        }

        const result = await ProductionActions.getAllProductions(
            userData,
            datePickerBefore,
            datePickerAfter,
            optionalProps,
            false,
            `&page_size=${productionsPaginationMetadata.pageSize}&page=${page}&${productionsFilterQuery}`
        );

        if (result.success) {
            setProductionsData(result.data);
            setProductionsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (productionsPaginationMetadata.totalItems !== result.metadata.count) {
                setProductionsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des frais');
        }

        setDatePickerLoading(false);
    };

    const handleGetAllExpenses = async (page, activeLoading = true) => {
        if(activeLoading) {
            setDatePickerLoading(true);
            setExpensesData([]);
        }

        const datePickerBefore = standardFormat(new Date(datePicker.endDate));
        const datePickerAfter = standardFormat(new Date(datePicker.startDate));

        const optionalProps = {
            deal_id: dealData.id
        }

        const result = await ExpenseActions.getAllExpenses(
            userData,
            datePickerBefore,
            datePickerAfter,
            optionalProps,
            `&page_size=${expensesPaginationMetadata.pageSize}&page=${page}&${expensesFilterQuery}`
        );

        if (result.success) {
            setExpensesData(result.data.filter((expense) => expense.report));

            setExpensesPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (expensesPaginationMetadata.totalItems !== result.metadata.count) {
                setExpensesPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des frais');
        }

        setDatePickerLoading(false);
    };

    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({
            action: ContractorActions.getAllContractors,
            search: `&search=${search}`,
            page: page || null,
            setter: setContractors,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetACBC = async (search, page = 1, customerId) => {
        if (!customerId) return;

        await fetchSelectData({
            action: DealActions.getAllDeals,
            search: `&search=${search}&type=framework&type=purchase_order&customer=${customerId}`,
            page: page || null,
            setter: setACBC,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetRessources = async (search, page = 1) => {
        const result = await UserActions.getAllSortedUsers(
            userData,
            `&search=${search}`,
            (page = 1)
        );

        const options = result.data?.results.map((ressource) => ({
            ...ressource,
            name: ressource.first_name + ' ' + ressource.last_name
        }));

        const hasNextPage = result.data?.next ? true : false;

        if (page === 1 || !page || search) {
            setRessources({ options, hasNextPage });
        }
        else {
            setRessources((prevData) => ({
                options: [...prevData.options, ...options],
                hasNextPage
            }));
        }
    };

    const handleGetDealOrigins = async (search, page = 1) => {
        const filterOrFind = (origin) => origin.is_active;
        await fetchSelectData({
            action: DealOriginsActions.getAllDealOrigins,
            search: `&search=${search}`,
            page: page || null,
            setter: setDealOrigins,
            userData,
            filterOrFind
        });
    };

    const handleGetCustomers = async (search, page = 1) => {
        await fetchSelectData({
            action: CustomerActions.getAllCustomers,
            search: `&search=${search}`,
            page: page || null,
            setter: setCustomers,
            userData
        });
    };

    // -- EFFECTS --

    // Get dropdows data
    useEffect(() => {
        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();
        (async () => {
            await handleGetRessources('', 1);
        })();

    }, []);

    // Filters
    useEffect(() => {
        handleGetAllMissionsData(1);
    }, [missionFilterQuery]);

    useEffect(() => {
        if (dealData) {
            handleGetAllSpendingsData(1);
        }
    }, [spendingsFilterQuery, dealData]);

    useEffect(() => {
        if (dealData) {
            handleGetAllContractorInvoicesData(1);
        }
    }, [contractorInvoicesFilterQuery, dealData]);

    useEffect(() => {
        if (dealData) {
            handleGetAllInvoices(1);
        }
    }, [invoicesFilterQuery, dealData]);

    useEffect(() => {
        handleGetAllProductions(1);
    }, [productionsFilterQuery, datePicker]);

    useEffect(() => {
        handleGetAllExpenses(1);
    }, [expensesFilterQuery, datePicker]);

    // Get data
    useEffect(() => {
        (async () => {
            setLoading(true);

            const subsidiaries = await SubsidiaryActions.getAllSubsidiaries(userData);

            const result = await DealActions.getDealById(userData, id);

            if (result.success) {

                if (result.data.parent) {
                    const parentResult = await DealActions.getDealById(userData, result.data.parent.id);

                    if (parentResult.success) {
                        setParentData(parentResult.data);
                    }
                }

                const isSale = result.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

                // -- Navigate to user's mission if he's role is consultant and he isnt the production lead of the deal --
                if (userData.role === 'CO' && result.data.production_lead.id !== userData.userId && !isSale) {

                    navigate(`/deals/${result.data.id}/missions/${result.data.producers.find((producer) => producer.id === userData.userId).id}`);

                    return
                }

            }
            else {
                navigate('/deals');
            }

            setDealData(result.data);

            if (subsidiaries.success) {
                const subsidiary = subsidiaries.data.filter((subs) => subs.name === result.data.subsidiary)[0];

                if (subsidiary) setIsTraining(subsidiary.is_training);
            }

            setLoading(false);
        })();
    }, [])

    // Get deal expenses and productions data
    useEffect(() => {
        (async () => {

            const optionalProps = {
                deal: id
            }

            const result = await AttachmentActions.getAllAttachments(userData, optionalProps);

            if (result.success) {
                setAttachmentsData(result.data.results);
            }
            else {
                notify('Erreur de récuperation des pièces jointes');
            }
        })();

        setDatePickerLoading(false);
    }, [datePicker])

    // Check navigation message existence
    useEffect(() => {
        if (location.state === 'mission_deleted') {
            notify('Mission supprimée');
        }
        else  if (location.state === 'invalid_mission_id') {
            notify('Mission inexistante');
        }
        history.replace({...history.location, state: null});
    }, []);

    useEffect(() => {
        if (!dealData?.sales) return;
        setSaleValues(dealData.sales);
    }, [dealData])

    // -- FUNCTIONS --

    const handleDeleteDeal = async () => {
        setButtonsDisabled(true);
        setDeleteDealLoading(true);

        const deleteDealResult = await DealActions.deleteDeal(userData, id);

        if (deleteDealResult.success) {
            navigate('/deals', {state: 'deal_deleted'});
        }
        else {
            notify('Erreur lors de la suppression de l\'affaire');
        }

        setDeleteDealLoading(false);
        setButtonsDisabled(false);
    }

    const refreshDealData = async () => {
        const subsidiaries = await SubsidiaryActions.getAllSubsidiaries(userData);

        const result = await DealActions.getDealById(userData, id);

        if (result.success) {
            setDealData(result.data);

            if (subsidiaries.success) {
                const subsidiary = subsidiaries.data.filter((subs) => subs.name === result.data.subsidiary)[0];

                if (subsidiary) setIsTraining(subsidiary.is_training);
            }
        }
        else {
            notify('Erreur lors de la mise àjour des donées');
        }
    }

    const refreshMissionsData = async () => {
        await handleGetAllMissionsData(missionPaginationMetadata.currentPage, false);
    }

    const refreshSpendingsData = async (removal = false) => {
        const checkedPage = (spendingsData.length === 1 && spendingsPaginationMetadata.currentPage > 1 && removal) ?
            spendingsPaginationMetadata.currentPage - 1 :
            spendingsPaginationMetadata.currentPage;

        await handleGetAllSpendingsData(checkedPage, false);
    }

    const refreshProductionsData = async (removal = false) => {
        const checkedPage = (productionsData.length === 1 && productionsPaginationMetadata.currentPage > 1 && removal) ?
            productionsPaginationMetadata.currentPage - 1 :
            productionsPaginationMetadata.currentPage;

        await handleGetAllProductions(checkedPage, false);
    };

    const refreshExpensesData = async (removal = false) => {
        const checkedPage = (expensesData.length === 1 && expensesPaginationMetadata.currentPage > 1 && removal) ?
            expensesPaginationMetadata.currentPage - 1 :
            expensesPaginationMetadata.currentPage;

        await handleGetAllExpenses(checkedPage, false);
    }

    const refreshInvoicesData = async (removal =  false) => {
        const checkedPage = (invoiceData.length === 1 && invoicesPaginationMetadata.currentPage > 1 && removal) ?
            invoicesPaginationMetadata.currentPage - 1 :
            invoicesPaginationMetadata.currentPage;

        await handleGetAllInvoices(checkedPage, false);
    };

    const refreshContractorInvoiceData = async (removal = false) => {
        const checkedPage = (spendingsContractorData.length === 1 && contractorInvoicesPaginationMetadata.currentPage > 1 && removal) ?
            contractorInvoicesPaginationMetadata.currentPage - 1 :
            contractorInvoicesPaginationMetadata.currentPage;

        await handleGetAllContractorInvoicesData(checkedPage, false);
    };

    const refreshAttachmentsData = async () => {

        const optionalProps = {
            deal: id
        }

        const result = await AttachmentActions.getAllAttachments(userData, optionalProps);

        if (result.success) {
            setAttachmentsData(result.data.results);
        }
        else {
            notify('Erreur lors de la mise à jour des donées');
        }
    }

    const onPhaseSuccess = async () => {
        setStartEndDateVisible(true);
    }

    const onPhaseLoss = () => {
        setRefuseAcceptDeal(false);
        setLossMotifVisible(true);
    }

    // --------------------
    // STATUS FROM BACK END
    // --------------------
    // INTERNAL_DEAL = 0 (Activité interne)
    // OTHER_INTERNAL = 1 (Autres (TdA))
    // SPECIFICATIONS_REQUIRED = 100 (Cahier des Charges demandé)
    // SPECIFICATIONS_STUDY = 150 (Étude CdC)
    // OUT_OF_SCOPE = 190 (Abandonnée)
    // PROPOSAL_IN_PROGRESS = 200 (Réponse en cours)
    // ABANDONED = 290 (Abandonée)
    // PROPOSAL_DELIVERED = 300 (Offre remise)
    // LOST = 400 (Perdue)
    // WON = 500 (Gagnée)
    // PRODUCED = 600 (Produite)
    // BILLED = 700 (Facturée)
    // DONE = 900 (Close)
    // --------------------

    const internalDealStatus = {
        0: 0,
        900: 1
    }

    const status = {
        100: 0,
        150: 1,
        200: 2,
        300: 3,
        400: 4,
        500: 4,
        600: 5,
        700: 6,
        900: 7
    }

    const handleStepClick = async (step) => {
        setConfirmVisible(step);
    }

    const handleConfirm = async () => {
        if (isInternal) {
            updateInternalDeal(confirmVisible);
        }
        else if (confirmVisible === 1 && dealData.status === 100) {
            setAnalysisDeadlineVisible(true);
        }
        else if (confirmVisible === 2) {
            setGoNoGoPopupVisible(true);
        }
        else if (confirmVisible === 3) {
            setBusinessVisible(true);
        }
        else if (confirmVisible === 4) {
            setRefuseAcceptDeal(true);
        }
        else if (confirmVisible === 5 && dealData.status === 500) {
            checkCurrentMissionsExistence();
        }
        else if (confirmVisible === 6) {
            checkUnissuedInvoicesExistence();
        }
        else {
            updateDeal(confirmVisible);
        }
    }

    const updateDeal = async (step, resultStatus) => {

        setButtonsDisabled(true);
        setLoading(true);

        let newStatus = null;

        for (const key in status) {
            (status[key] === step) ? newStatus = key : null;
        }

        const payload = {
            status: resultStatus ? resultStatus : newStatus
        }

        const result = await DealActions.updateDeal(userData, payload, dealData.id);

        if (result.success) {
            await refreshDealData();
            notify('Le statut a été mis à jour');
        }
        else {
            notify('Une erreur est survenue lors du changement de statut');
        }

        setLoading(false);
        setButtonsDisabled(false);
    }

    const updateInternalDeal = async (step) => {
        setButtonsDisabled(true);
        setLoading(true);

        let newStatus = null;

        for (const key in internalDealStatus) {
            (internalDealStatus[key] === step) ? newStatus = key : null;
        }

        const payload = {
            status: newStatus
        }

        const result = await DealActions.updateDeal(userData, payload, dealData.id);

        if (result.success) {
            await refreshDealData();
            notify('Le statut a été mis à jour');
        }
        else {
            notify('Une erreur est survenue lors du changement de statut');
        }

        setLoading(false);
        setButtonsDisabled(false);
    }

    const checkCurrentMissionsExistence = async () => {
        setLoading(true);

        const result = await MissionActions.getAllMissions(
            userData,
            `&deal=${dealData.id}&page_size=${2000}&display=${userData.userProfileName}&status=0&status=1`,
            1
        );

        if (result.success && result.data.length > 0) {
            setLoading(false);
            setMissionsInProgressPopup(true);
            const missionIds = result.data.map(m => m.id);
            setMissionsInProgressIds(missionIds);
        }
        else {
            updateDeal(5, 600);
        }
    }

    const checkUnissuedInvoicesExistence = async () => {
        setLoading(true);

        const result = await InvoiceActions.getAllInvoices(
            userData,
            `&deal=${dealData.id}&page_size=200&status=0&status=50&status=100&status=200`,
            1
        );

        if (result.success && result.data.length > 0) {
            setLoading(false);
            setUnissuedInvoicesPopup(true);
        }
        else {
            updateDeal(6, 700);
        }
    }

    const updateUnfinishedMissions = async () => {
        setLoading(true);

        const promises = [];

        missionsInProgressIds.forEach((id) => {
            const request = MissionActions.updateMissionById(userData, {id: id, status: 2});

            promises.push(request);
        })

        Promise.all(promises)
            .then(() => {
                setMissionsInProgressIds([]);
                refreshMissionsData();
                updateDeal(5, 600);
            })
            .catch(() => {
                notify("Une erreur est survenue");
                setLoading(false);
            });
    }

    useEffect(() => {
        if ((dealData.status === 100 || dealData.status === 150) && !location.pathname.includes("informations")) {
            navigate(`/deals/${dealData.id}/informations`);
        }
    }, [dealData, location.pathname]);

    // Configs
    const roadStepConfig = roadStepDealsConfig(isInternal, isTraining, dealData);

    const bannerConfig = bannerDealsConfig(
        handleDeleteDeal,
        deleteDealLoading,
        buttonsDisabled,
        isTraining,
        cannotBeModified
    );

    const topBannerTitle = `[${dealData.customer?.name}] ${dealData.parent?.name ? `<${dealData.parent?.name}>` : ''} ${dealData.name}`;

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className='flex items-center justify-center w-full py-6'>
                    <RoadStep
                        declined={dealData.status === 400}
                        step={status[dealData.status]}
                        config={roadStepConfig}
                        onChange={handleStepClick}
                    />
                </div>
                <TopBanner
                    title= {topBannerTitle}
                    config={bannerConfig}
                    status={dealData.status}
                />
                <DealDetailsNavigation
                    userData={userData}
                    dealData={dealData}
                    missionsData={missionsData}
                    missionPaginationMetadata={missionPaginationMetadata}
                    handleGetAllMissionsData={handleGetAllMissionsData}
                    missionTableLoading={missionTableLoading}
                    setSpendingsData={setSpendingsData}
                    spendingsData={spendingsData}
                    spendingsTableLoading={spendingsTableLoading}
                    spendingsPaginationMetadata={spendingsPaginationMetadata}
                    setSpendingsFilterQuery={setSpendingsFilterQuery}
                    handleGetAllSpendingsData={handleGetAllSpendingsData}
                    invoicesData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    invoicesTableLoading={invoicesTableLoading}
                    setInvoicesFilterQuery={setInvoicesFilterQuery}
                    invoicesPaginationMetadata={invoicesPaginationMetadata}
                    handleGetAllInvoices={handleGetAllInvoices}
                    contracorInvoiceData={spendingsContractorData}
                    contractorInvoicesTableLoading={contractorInvoicesTableLoading}
                    setContractorInvoicesFilterQuery={setContractorInvoicesFilterQuery}
                    setContractorInvoiceData={setSpendingsContractorData}
                    contractorInvoicesPaginationMetadata={contractorInvoicesPaginationMetadata}
                    handleGetAllContractorInvoicesData={handleGetAllContractorInvoicesData}
                    productionsData={productionsData}
                    setProductionsFilterQuery={setProductionsFilterQuery}
                    handleGetAllProductions={handleGetAllProductions}
                    productionsPaginationMetadata={productionsPaginationMetadata}
                    setExpensesFilterQuery={setExpensesFilterQuery}
                    expensesData={expensesData}
                    expensesPaginationMetadata={expensesPaginationMetadata}
                    handleGetAllExpenses={handleGetAllExpenses}
                    attachmentsData={attachmentsData}
                    datePicker={datePicker}
                    setDatePicker={setDatePicker}
                    buttonsDisabled={buttonsDisabled}
                    setButtonsDisabled={setButtonsDisabled}
                    refreshDealData={refreshDealData}
                    refreshMissionsData={refreshMissionsData}
                    refreshSpendingsData={refreshSpendingsData}
                    refreshContractorInvoiceData={refreshContractorInvoiceData}
                    refreshProductionsData={refreshProductionsData}
                    refreshExpensesData={refreshExpensesData}
                    refreshInvoicesData={refreshInvoicesData}
                    refreshAttachmentsData={refreshAttachmentsData}
                    datePickerLoading={datePickerLoading}
                    setDatePickerLoading={setDatePickerLoading}
                    isTraining={isTraining}
                    isInternal={isInternal}
                    setMissionFilterQuery={setMissionFilterQuery}
                />
            </PageLoader>
            <ConfirmationPopup
                view={confirmVisible}
                setView={setConfirmVisible}
                title={'Confirmer le changement de statut?'}
                subtitle={'Le statut de l\'affaire sera mis à jour.'}
                onConfirm={handleConfirm}
                blueStyle={isTraining}
            />
            <ConfirmationPopup
                view={refuseAcceptDeal}
                setView={setRefuseAcceptDeal}
                title={'Résultat de la phase commerciale'}
                subtitle={'Sélectionnez le résutat de la phase commerciale.'}
                onDecline={onPhaseLoss}
                onConfirm={onPhaseSuccess}
                declineButtonText={'Echec'}
                confirmButtonText={'Réussite'}
                blueStyle={isTraining}
            />
            <ConfirmationPopup
                view={missionsInProgressPopup}
                setView={setMissionsInProgressPopup}
                title={'Cette affaire a des missions non terminées'}
                subtitle={'Voulez-vous marquer ces missions comme terminées ?'}
                onConfirm={updateUnfinishedMissions}
                declineButtonText={'Non'}
                confirmButtonText={'Oui'}
                blueStyle={isTraining}
            />
            <ConfirmationPopup
                view={unissuedInvoicesPopup}
                setView={setUnissuedInvoicesPopup}
                title={'Cette affaire a des factures non émises'}
                subtitle="Vous ne pouvez pas changer le statut de cette affaire car elle comporte des factures au statut non émises"
                onConfirm={() => setUnissuedInvoicesPopup(false)}
                visibleDeclineButton={false}
                confirmButtonText="J'ai compris"
                blueStyle={isTraining}
            />
            <RefusalMotiff
                userData={userData}
                view={lossMotifVisible}
                setView={setLossMotifVisible}
                dealData={dealData}
                refreshDealData={refreshDealData}
                refreshMissionsData={refreshMissionsData}
                blueStyle={isTraining}
            />
            <AnalysisDeadlinePopup
                userData={userData}
                dealId={dealData.id}
                refreshDealData={refreshDealData}
                view={analysisDeadlineVisible}
                setView={setAnalysisDeadlineVisible}
                blueStyle={isTraining}
            />
            <ResponseDeadlinePopup
                userData={userData}
                view={responseDeadlineVisible}
                setView={setResponseDeadlineVisible}
                dealId={dealData.id}
                refreshDealData={refreshDealData}
                blueStyle={isTraining}
            />
            <ResponseDeadlinePopup
                userData={userData}
                view={responseDeadlineVisible}
                setView={setResponseDeadlineVisible}
                dealId={dealData.id}
                refreshDealData={refreshDealData}
                blueStyle={isTraining}
            />
            <GoNoGoPopup
                userData={userData}
                dealId={dealData.id}
                view={goNoGoPopupVisible}
                setView={setGoNoGoPopupVisible}
                setAbandonedPopupVisible={setAbandonedPopupVisible}
                refreshDealData={refreshDealData}
                setResponsePopupVisible={setResponseDeadlineVisible}
                blueStyle={isTraining}
            />
            <AbandonedPopup
                userData={userData}
                view={abandonedPopupVisible}
                setView={setAbandonedPopupVisible}
                refreshDealData={refreshDealData}
                dealId={dealData.id}
                blueStyle={isTraining}
            />
            <StartEndDatePopup
                userData={userData}
                view={startEndDateVisible}
                setView={setStartEndDateVisible}
                dealData={dealData}
                refreshDealData={refreshDealData}
                refreshMissionsData={refreshMissionsData}
                blueStyle={isTraining}
                setNextStep={setInvoicePopupView}
            />
            <BusinessRoadstepPopup
                dealData={dealData}
                accordCadreValue={parentData}
                refreshData={refreshDealData}
                refreshMissionsData={refreshMissionsData}
                view={businessVisible}
                setView={setBusinessVisible}
                userData={userData}
                handleGetCustomers={handleGetCustomers}
                handleGetDealOrigins={handleGetDealOrigins}
                handleGetRessources={handleGetRessources}
                handleGetContractors={handleGetContractors}
                partnerValues={partnerValues}
                setPartnerValues={setPartnerValues}
                saleValues={saleValues}
                setSaleValues={setSaleValues}
                productionRessourcesValues={productionRessourcesValues}
                setProductionRessourcesValues={setProductionRessourcesValues}
                pilotsValues={pilotsValues}
                setPilotsValues={setPilotsValues}
                contractors={contractors}
                subsidiaryCategories={subsidiaryCategories}
                customers={customers}
                dealOrigins={dealOrigins}
                dealDas={dealDas}
                ressources={ressources}
                setCustomerType={setCustomerType}
            />
            <Popup
                view={invoicePopupView}
                setView={setInvoicePopupView}
                hideOnClick={false}
                width={'1000px'}
            >
                <InvoicePopup
                    dealData={dealData}
                    setView={setInvoicePopupView}
                    refreshData={refreshInvoicesData}
                    blueStyle={isTraining}
                    cancelDisabled={!invoiceData.length}
                />
            </Popup>
        </Layout>
    );
}

export default DealDetailsPage;
