import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import {dealInvoicesTableConfig} from '../../configs/sectionConfigs/dealInvoicesTableConfig';
import InvoicePopup from '../../popups/dealDetailPopups/InvoicePopup';
import {InvoiceActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import Popup from '../../../../components/ui/Popup/Popup';

const InvoicesSection = ({
    invoicesData,
    dealData,
    refreshInvoicesData,
    buttonsDisabled,
    setButtonsDisabled,
    blueStyle,
    invoicesTableLoading,
    setInvoicesFilterQuery,
    invoicesPaginationMetadata,
    handleGetAllInvoices,
    cannotBeModified
}) => {
    const [deleteInvoiceLoading, setDeleteInvoiceLoading] = useState(null);
    const [invoicePopupView, setInvoicePopupView] = useState(false);
    // const [invoiceToBeUpdated, setInvoiceToBeUpdated] = useState(null);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDeleteInvoice = async (id) => {
        setButtonsDisabled(true);
        setDeleteInvoiceLoading(id);

        const deleteInvoiceResult = await InvoiceActions.deleteInvoiceById(userData, id);

        if (!deleteInvoiceResult.success) {
            notify('Erreur lors de la suppression de la facture');
        }
        else {
            await refreshInvoicesData(true);
        }

        setDeleteInvoiceLoading(null);
        setButtonsDisabled(false);
    }

    const filterInvoiceList = async (query) => {
        setInvoicesFilterQuery(query);
    }

    const invoicesTableConfig = dealInvoicesTableConfig(
        handleDeleteInvoice,
        deleteInvoiceLoading,
        buttonsDisabled,
        blueStyle,
        filterInvoiceList,
        userData,
        cannotBeModified
    );

    return (
        <>
            <ContainerCard
                title={dealData.type === 'framework' || dealData.type === 'purchase_order' ? 'Bilan des factures' : 'Factures'}
                buttonContent='Nouvelle facture'
                onButtonClick={() => setInvoicePopupView(true)}
                blueStyle={blueStyle}
                overflow='inherit'
                coloredButtonDisplayed={!cannotBeModified && !(dealData.type === 'framework' || dealData.type === 'purchase_order')}
            >
                <Table
                    data={invoicesData}
                    tableConfig={invoicesTableConfig}
                    loading={invoicesTableLoading}
                    paginationMetadata={invoicesPaginationMetadata}
                    onPageNumberChange={handleGetAllInvoices}
                />
            </ContainerCard>
            <Popup
                view={invoicePopupView}
                setView={setInvoicePopupView}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
                maxHeight='48rem'
            >
                <InvoicePopup
                    dealData={dealData}
                    setView={setInvoicePopupView}
                    refreshData={refreshInvoicesData}
                    blueStyle={blueStyle}
                />
            </Popup>
        </>
    )
}

export default InvoicesSection;
